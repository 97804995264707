import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Autocomplete, CardActionArea, CardActions, MenuItem, Select } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import ApplicationServices from '../../services/applicationService';
import { AddApplication } from '../../services/applicationType';
import { LaunchApplication } from '../../services/applicationType';
import Loader from '../Loader';
import Auth from '../../services/authService';
import Swal from 'sweetalert2';
import {
  InviteSomeone,
  DeleteApplication,
  AcceptInviteApplication,
  DeclineInviteApplication,
} from '../../services/authType';
import { useNavigate } from 'react-router-dom';
import WithAuth from '../../provider/withAuth';
import moment from 'moment';
import AsyncSelect from 'react-select/async';

function Dashboard() {

  const [isLoading, setIsLoading] = useState(false);
  const [applicationData, setApplicationData] = useState([]);

  // Eplod_3 Form Modal









  // Eplod_3 Form Modal
  const [Catalog_Form_open, Catalog_Form_setOpen] = useState(false);
  const [appData, setAppData] = useState("") as any
  const Catalog_Form_Modal = (item: any) => {
    setCatalogName('');
    setCatalogError('');
    Catalog_Form_setOpen(true);
    setAppData(item)
  };
  const Catalog_Form_Modal_Close = () => {
    Catalog_Form_setOpen(false);
  };
  const [catalogName, setCatalogName] = useState('');
  const [catalogError, setCatalogError] = useState('');




  const handleCatalogNameChange = (event: any) => {
    const name = event.target.value.trim();
    if (name == '') {
      setCatalogError('Type is required');
    } else if (name.length > 50) {
      setCatalogError('Type cannot exceed 50 characters');
    } else {
      setCatalogError('');
      setCatalogName(name);
    }
  };

  const handleSubmitCatalog = async () => {
    if (catalogError == '') {
      const payload: AddApplication = {
        type: appData.appCode,
        name: catalogName,
      };
      try {
        setIsLoading(true);
        const response = await ApplicationServices.addApplications(payload);
        const data = response;

        if (data) {
          Swal.fire({
            icon: 'success',
            title: 'Added successfully',
          });
          fetchApplicationOwnData();
        } else {
          setCatalogError('Something Went Wrong');
        }
        setIsLoading(false);
        Catalog_Form_setOpen(false);
      } catch (error) {
        setIsLoading(false);
        Catalog_Form_setOpen(false);
        console.error('Error while adding data:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to Add Application',
        });
      }
    } else {
      // setCatalogError('Type is required & less then 50 character')
    }
  };

  // Only_Forms Form Modal




  const fetchApplicationOwnData = async () => {
    try {
      setIsLoading(true);
      const response = await ApplicationServices.applicationsOwn();
      setApplicationData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApplicationOwnData();
    fetchApplicationAccessData();
  }, []);

  const navigateOnDashboard = () => {
    Invite_User_setOpen(false);
  };

  const [applicationAccessData, setApplicationAccessData] = useState([]);

  const fetchApplicationAccessData = async () => {
    try {
      setIsLoading(true);
      const response = await Auth.ApplicationsAccess();
      if (response) {
        setApplicationAccessData(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleAcceptInvite = async (ID: any) => {
    const payload: AcceptInviteApplication = {
      applicationID: ID,
    };
    try {
      setIsLoading(true);
      const response = await Auth.AcceptInvite(payload);
      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Accepted Successfully',
          // text: `Invitation sent to ${userListData.firstname} ${userListData.lastname}.`,
        });
        fetchApplicationAccessData();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: 'warning',
        title: 'Something Went Wrong',
        text: ' Try Again Later',
        // text: `Invitation sent to ${userListData.firstname} ${userListData.lastname}.`,
      });
    }
  };


  const handleDeclineApplication = async (ID: any) => {
    const payload: DeclineInviteApplication = {
      ID: ID,
    };
    const swalResult = await Swal.fire({
      icon: 'warning',
      title: 'Delete Application',
      text: 'Are you sure to decline this invitation?',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    });

    if (swalResult.isConfirmed) {
      try {
        setIsLoading(true);
        const response = await Auth.DeclineInvite(payload);
        if (response) {
          Swal.fire({
            icon: 'success',
            title: 'Declined Successfully',
            // text: `Invitation sent to ${userListData.firstname} ${userListData.lastname}.`,
          });
          fetchApplicationAccessData();
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        Swal.fire({
          icon: 'warning',
          title: 'Something Went Wrong',
          text: ' Try Again Later',
          // text: `Invitation sent to ${userListData.firstname} ${userListData.lastname}.`,
        });
      }
    } else {
    }
  };

  const [Invite_User_open, Invite_User_setOpen] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState(0);
  const [applicationGiven, setApplicationGiven] = useState('');

  const handleInviteUser = (ID: any, applicationGivenName: any) => {
    setUserName(0);
    Invite_User_setOpen(true);
    setSelectedDropDownError(false);
    setResponseFailedError('');
    setSelectedApplicationId(ID);
    setApplicationGiven(applicationGivenName);
  };

  const handleInviteUserClose = () => {
    Invite_User_setOpen(false);
  };

  const [userName, setUserName] = useState(0);
  const [userListData, setUserListData] = useState([]);

  const [value, setValue] = useState("")

  const fetchUserList = async () => {
    try {
      setIsLoading(true);
      const response = await Auth.listOfUsers(value);
      const data = response;

      if (data) {
        setUserListData(data);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, [value]);


  const [appsData, setAppsData] = useState([]);

  const fetchAppsList = async () => {
    try {
      setIsLoading(true);
      const response = await Auth.listOfApps();
      const data = response;

      if (data) {
        setAppsData(data);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAppsList();
  }, []);





  const onUserDropDown = (event: any) => {
    setSelectedDropDownError(false);
    setResponseFailedError('');
    setUserName(event.target.value);
  };

  const [selectedDropDownError, setSelectedDropDownError] = useState(false);
  const [responseFaliedError, setResponseFailedError] = useState('');

  const handleInviteUserClick = async () => {
    if (!userName) {
      setSelectedDropDownError(true);
    } else {
      setSelectedDropDownError(false);
      const payload: InviteSomeone = {
        applicationID: selectedApplicationId,
        ID: userName,
      };
      try {
        setIsLoading(true);
        const response = await Auth.inviteSomeone(payload);
        const data = response;

        if (data) {
          Swal.fire({
            icon: 'success',
            title: 'Invitation Sent',
            // text: `Invitation sent to ${userListData.firstname} ${userListData.lastname}.`,
          });
        } else {
          setResponseFailedError(
            `Already sent Invitation sent to ${applicationGiven}.`,
          );
          setIsLoading(false);
        }
        setIsLoading(false);
        Invite_User_setOpen(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error while sending invitation:', error);
        setResponseFailedError(
          'Failed to send invitation',
        );
      }
    }
  };

  const handleDeleteApplication = async (ID: any) => {
    const payload: DeleteApplication = {
      ID: ID,
    };
    const swalResult = await Swal.fire({
      icon: 'warning',
      title: 'Delete Application',
      text: 'Are you sure to delete this Application ?',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    });

    if (swalResult.isConfirmed) {
      try {
        setIsLoading(true);
        const response = await Auth.deleteApplication(payload);
        const data = response;

        if (data) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted successfully',
          });

          fetchApplicationOwnData();
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error while delete application:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to delete application',
        });
      }
    } else {
      // You can add code here for any action you want to take when the user cancels the deletion.
    }
  };

  const handleLaunchApplication = async (ID: any) => {
    const payload: LaunchApplication = {
      applicationID: ID,
    };

    const swalResult = await Swal.fire({
      text: 'You want to launch application ?',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    });
    if (swalResult.isConfirmed) {
      try {
        setIsLoading(true);
        const response = await ApplicationServices.launchApplication(payload);
        if (response?.LoginOk) {
          // window.open(response?.LaunchURL);
          let a = document.createElement('a');
          a.href = response?.LaunchURL;
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      // You can add code here for any action you want to take when the user cancels the deletion.
    }
  };
  const [selectedOption, setSelectedOption] = useState("");

  const loadOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    setTimeout(() => {
      Auth.listOfUsers(inputValue).then((res: any) => {
        const data = res.map((result: any) => ({

          label: result.firstname + ' ' + result.lastname,
          value: result.ID
        }))
        callback(data)
      });

    }, 1000);
  };

  const onChangeSelectedOption = (e: any) => {
    setUserName(e.value);
    setSelectedOption(e);
  }


  return (
    <>
      <div>
        {/* Hero Banner Section */}

        {isLoading && <Loader />}

        <Box component="section" className="Dashborad_HeroBanner_section" height={350}>
          <Container maxWidth="xl">
            <Box className="Dashborad_welcome_heading">

              <Typography
                component="h3"
                variant="h3"
                fontWeight="600"
                textAlign="center"
              >
                <Box component="span" color="red">
                  REDOCHRE
                </Box>{' '}
                SOFTWARE
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                maxWidth="md"
                textAlign="center"
                m="auto"
              >
                Red Ochre Application Dashboard. Share Applications. Maintain
                Apps and licences all in one place. Scroll down to see featured
                apps.
              </Typography>
            </Box>
            <Paper component="form" className="dashborad_search_Area">
              <InputBase
                sx={{ ml: 2, flex: 1 }}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Container>
        </Box>
        {/* End Hero Banner Section */}

        {/* App Store Section */}
        <Box
          component="section"
          className="App_Store_section Applications_own_Section"
        >
          <Container maxWidth="xl">
            <Box className="Applications_own_Heading" sx={{ mb: 5 }}>
              <Typography
                component="h4"
                variant="h4"
                fontWeight="600"
                textAlign="center"
              >
                Featured Apps
              </Typography>
              <Typography
                component="p"
                variant="subtitle2"
                maxWidth="sm"
                textAlign="center"
                m="auto"
              >
                Featured Apps can be deployed to your workarea. Application
                deployment is only two clicks away.
              </Typography>
            </Box>
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: 'center', alignItems: 'center' }}
            >

              <Grid item xs={12} sm={12} md={6} lg={5}>
                <Box>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    className="App_Store_form_area"
                  >
                    {appsData.map((item: any) => (

                      <Grid
                        className="App_Store_form_items"
                        item
                        xs={12}
                        sm={6}
                        lg={6}
                        md={6}
                      >
                        <Card
                          sx={{ maxWidth: '100%', borderRadius: '10px', height: "100%" }}
                          onClick={() => Catalog_Form_Modal(item)}
                        >
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              image={item?.smallImageURL}
                              alt="green iguana"
                              className="Eplod_3_Form_img"
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                {item?.title}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {item?.shortDescription}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>

                      </Grid>

                    ))}

                    <Dialog
                      open={Catalog_Form_open}
                      className="Eplod_3_Form_modal_section"
                    >
                      <DialogTitle
                        component="h3"
                        sx={{ pb: 1, fontWeight: 600 }}
                      >
                        {appData?.title ?? ""}
                      </DialogTitle>
                      <DialogContent className="Eplod_3_Form_modal_content">
                        <DialogContentText
                          sx={{ mb: 3, mt: 0, fontSize: '14px' }}
                        >
                          To deploy this application - please enter an application name and submit to deploy.
                          Your name  or the name of your business or some other identifier
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="Application_Type"
                          label="Application Type"
                          type="text"
                          fullWidth
                          defaultValue={appData?.appCode}
                          variant="standard"

                          disabled
                        />
                        <TextField
                          autoFocus
                          margin="dense"
                          id="Application_Name"
                          label="Application Name"
                          type="text"
                          fullWidth
                          variant="standard"
                          onChange={handleCatalogNameChange}
                          error={!!catalogError}
                          helperText={catalogError}
                        />
                      </DialogContent>
                      <DialogActions className="Eplod_3_Form_modal_button">
                        <Button
                          variant="contained"
                          color="success"
                          onClick={handleSubmitCatalog}
                          disabled={!catalogName || catalogName.length > 50}
                        >
                          {isLoading ? 'Loading...' : ' Submit Details'}
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={Catalog_Form_Modal_Close}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>


                    {/* <Grid
                      className="App_Store_form_items"
                      item
                      xs={12}
                      sm={6}
                      lg={6}
                      md={6}
                    >
                      <Card
                        sx={{ maxWidth: '100%', borderRadius: '10px' }}
                        onClick={Only_Forms_Modal}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            image="/Images/contactform2.png"
                            //image="/Images/Forms-bg.png"
                            alt="green iguana"
                            className="Eplod_3_Form_img"
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              Only Forms
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Design Forms: Push to tablet. Populate Forms. Send
                              to supervisor. Sign and file. Available Q2 2024
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <Dialog
                        open={Only_Forms_open}
                        className="Eplod_3_Form_modal_section"
                      >
                        <DialogTitle
                          component="h3"
                          sx={{ pb: 1, fontWeight: 600 }}
                        >
                          Only Forms
                        </DialogTitle>
                        <DialogContent className="Eplod_3_Form_modal_content">
                          <DialogContentText
                            sx={{ mb: 3, mt: 0, fontSize: '14px' }}
                          >
                            To subscribe to this website, please enter your
                            email address here. We will send updates
                            occasionally.
                          </DialogContentText>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="Application_Type"
                            label="Application Type"
                            type="text"
                            fullWidth
                            defaultValue="E_App_OnlyForms"
                            variant="standard"
                            onChange={handleOnlyFormTypeChange}
                            disabled
                          />
                          <TextField
                            autoFocus
                            margin="dense"
                            id="Application_Name"
                            label="Application Type"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleOnlyFormNameChange}
                            error={!!onlyFormError}
                            helperText={onlyFormError}
                          />
                        </DialogContent>
                        <DialogActions className="Eplod_3_Form_modal_button">
                          <Button
                            variant="contained"
                            color="success"
                            onClick={handleSubmitOnlyForm}
                            disabled={!onlyFormName || onlyFormName.length > 50}
                          >
                            {isLoading ? 'Loading...' : ' Submit Details'}
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={Only_Forms_Modal_Close}
                          >
                            Cancel
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid> */}

                    {/* <Grid
                      className="App_Store_form_items"
                      item
                      xs={12}
                      sm={6}
                      lg={6}
                      md={6}
                    >
                      <Card
                        sx={{ maxWidth: '100%', borderRadius: '10px' }}
                        onClick={Eplod_31_Modal}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            image="/Images/SmallImage.png"
                            //  image="/Images/Forms-bg.png"
                            alt="green iguana"
                            className="Eplod_3_Form_img"
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              DRILL DOWN
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Drill Down: Exploration or Geo Drilling Plod and
                              Contract Management Software. Available Now. Click
                              Image to deploy.
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <Dialog
                        open={Eplod_31_Modal_open}
                        className="Eplod_3_Form_modal_section"
                      >
                        <DialogTitle
                          component="h3"
                          sx={{ pb: 1, fontWeight: 600 }}
                        >
                          Eplod 3.1
                        </DialogTitle>
                        <DialogContent className="Eplod_3_Form_modal_content">
                          <DialogContentText
                            sx={{ mb: 3, mt: 0, fontSize: '14px' }}
                          >
                            To subscribe to this website, please enter your
                            email address here. We will send updates
                            occasionally.
                          </DialogContentText>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="Application_Type"
                            label="Application Type"
                            type="text"
                            fullWidth
                            defaultValue="E_App_Eplod31"
                            variant="standard"
                            onChange={handleEploadThreeTypeChange}
                            disabled
                          />
                          <TextField
                            autoFocus
                            margin="dense"
                            id="Application_Name"
                            label="Application Type"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleEploadThreeNameChange}
                            error={!!eploadThreeError}
                            helperText={eploadThreeError}
                          />
                        </DialogContent>
                        <DialogActions className="Eplod_3_Form_modal_button">
                          <Button
                            variant="contained"
                            color="success"
                            onClick={handleSubmitEploadThree}
                            disabled={
                              !eploadThreeName || eploadThreeName.length > 50
                            }
                          >
                            {isLoading ? 'Loading...' : ' Submit Details'}
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={Eplod_31_Modal_Close}
                          >
                            Cancel
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid> */}
                  </Grid>

                </Box>
              </Grid>


            </Grid>
          </Container>
        </Box>

        {/* Applications_own_Section Section */}
        {applicationData?.length > 0 && (
          <Box
            component="section"
            className="Applications_own_Section"
            sx={{ marginBottom: '0px' }}
          >
            <Container maxWidth="xl">
              <Box className="Applications_own_Heading" sx={{ mb: 5 }}>
                <Typography
                  component="h4"
                  variant="h4"
                  fontWeight="600"
                  textAlign="center"
                >
                  My Applications
                </Typography>
                <Typography
                  component="p"
                  variant="subtitle2"
                  maxWidth="sm"
                  textAlign="center"
                  m="auto"
                >
                  Applications who's ownership is all yours.
                </Typography>
              </Box>

              <Grid
                container
                spacing={2}
                sx={{ justifyContent: 'center', alignItems: 'stretch' }}
              >
                {applicationData.map((item: any) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    md={4}
                    sx={{ mb: 2 }}
                    key={item.id}
                  >
                    <Card
                      sx={{
                        maxWidth: '100%',
                        borderRadius: '10px',
                        height: '100%',
                      }}
                    >
                      <CardActionArea sx={{ height: '100%' }}>
                        <CardMedia
                          component="img"
                          height="80"
                          image={item?.Link_Value?.SmallImageURL}
                          alt="green iguana"
                          sx={{ objectFit: 'contain', marginTop: '12px' }}
                          onClick={() =>
                            handleLaunchApplication(item?.Link_Value?.ID)
                          }
                        />
                        <CardContent>
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{
                              fontSize: '20px',
                              fontWeight: 700,
                              textAlign: 'center',
                              marginBottom: '0px',
                            }}
                          >
                            ID: {item?.Link_Value?.ID}
                          </Typography>
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{
                              fontSize: '18px',
                              fontWeight: 700,
                              textAlign: 'center',
                              color: '#1976d2',
                              wordBreak: 'break-word',
                            }}
                          >
                            {item?.Link_Value?.ApplicationName}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <Grid
                              container
                              rowSpacing={1}
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                              sx={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Grid item>
                                <Typography
                                  gutterBottom
                                  component="div"
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    textAlign: 'center',
                                    color: '#1976d2',
                                    paddingTop: '8px',
                                  }}
                                >
                                  Application Type Desc:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sx={{ paddingTop: '0px', fontSize: '12px' }}
                              >
                                {item?.Link_Value?.ApplicationTypeDesc}
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <Grid
                              container
                              rowSpacing={1}
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                              sx={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingTop: '0px',
                              }}
                            >
                              {/* <Grid item>
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{
                              fontSize: '14px',
                              fontWeight: 700,
                              textAlign: 'center',
                              color: '#1976d2',
                              paddingTop: '0px',
                              marginBottom: '0px',
                            }}
                          >
                            Entry Point URL:
                          </Typography>
                        </Grid>
                        <Grid item sx={{ paddingTop: '0px', fontSize: '12px' }}>
                        {item?.Link_Value?.EntryPointURL}  
                        </Grid>   */}
                            </Grid>
                          </Typography>

                          {/*------------------- modal invite --------------- */}
                        </CardContent>
                        <CardActions>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            sx={{ mt: 0 }}
                          >
                            <Grid item xs={6} sm={6} md={6}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                  mb: 1,
                                  fontWeight: '700',
                                  background: '#1976d2',
                                }}
                                onClick={() =>
                                  handleInviteUser(
                                    item?.Link_Value?.ID,
                                    item?.Link_Value?.ApplicationName,
                                  )
                                }
                              >
                                Invite
                              </Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                color="error"
                                sx={{ mb: 1, fontWeight: '700' }}
                                onClick={() =>
                                  handleDeleteApplication(item?.Link_Value?.ID)
                                }
                              >
                                Remove
                              </Button>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
                <Dialog
                  sx={{ '& .MuiPaper-root': { overflowY: 'visible' } }}
                  open={Invite_User_open}
                  className="Eplod_3_Form_modal_section"
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"

                >


                  <DialogContent className="Eplod_3_Form_modal_content" sx={{ width: '350px', overflowY: 'visible' }}>
                    <CardMedia
                      component="img"
                      height="80"
                      image="/Images/crane.png"
                      alt="green iguana"
                      sx={{ objectFit: 'contain', marginTop: '12px' }}
                      onClick={navigateOnDashboard}
                    />
                    <Typography
                      gutterBottom
                      component="div"
                      sx={{
                        fontSize: '20px',
                        fontWeight: 700,
                        textAlign: 'center',
                        marginBottom: '0px',
                      }}
                    >
                      ID: {selectedApplicationId}
                    </Typography>
                    <Typography
                      gutterBottom
                      component="div"
                      sx={{
                        fontSize: '18px',
                        fontWeight: 700,
                        textAlign: 'center',
                        color: '#1976d2',
                      }}
                    >
                      {applicationGiven}
                    </Typography>
                    <Grid item sx={{ paddingTop: '0px', fontSize: '12px' }}>
                      ePlodIII
                    </Grid>





                    <AsyncSelect cacheOptions loadOptions={loadOptions} defaultOptions

                      onChange={onChangeSelectedOption} />


                  </DialogContent>
                  <Typography
                    gutterBottom
                    component="div"
                    sx={{
                      overflowY: 'visible',
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '15px',
                    }}
                  >
                    {selectedDropDownError ? (
                      <span className="spanerror">Please Select User</span>
                    ) : (
                      <></>
                    )}
                    {responseFaliedError != '' ? (
                      <span className="spanerror">{responseFaliedError}</span>
                    ) : (
                      <></>
                    )}
                  </Typography>

                  <DialogActions className="Eplod_3_Form_modal_button">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleInviteUserClick}
                    >
                      Invite User
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleInviteUserClose}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Container>
          </Box>
        )}
        {/* End Applications_own_Section Section */}

        {/* Applications_own_Section Section */}
        <Box
          component="section"
          className="Applications_own_Section"
          sx={{ margin: '0px', background: 'none' }}
        >
          <Container maxWidth="xl">
            <Box className="Applications_own_Heading" sx={{ mb: 5 }}>
              <Typography
                component="h4"
                variant="h4"
                fontWeight="600"
                textAlign="center"
              >
                Connected Apps
              </Typography>
              <Typography
                component="p"
                variant="subtitle2"
                maxWidth="sm"
                textAlign="center"
                m="auto"
              >
                Applications deployed by others. These apps have been shared
                with you. Accept or decline your invite.
              </Typography>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ justifyContent: 'center', alignItems: 'stretch' }}
            >
              {applicationAccessData.map((item: any) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  md={4}
                  sx={{ mb: 2 }}
                  key={item.id}
                >
                  <Card
                    sx={{
                      maxWidth: '100%',
                      height: '100%',
                      borderRadius: '10px',
                      background: '#eaf4ff',
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="80"
                        image={item?.Link_Value?.SmallImageURL}
                        alt="green iguana"
                        sx={{ objectFit: 'contain', marginTop: '12px' }}
                        onClick={() =>
                          handleLaunchApplication(item?.Link_Value?.ID)
                        }
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          component="div"
                          sx={{
                            fontSize: '20px',
                            fontWeight: 700,
                            textAlign: 'center',
                            marginBottom: '0px',
                          }}
                        >
                          {item?.Link_Value?.ID}
                        </Typography>
                        <Typography
                          gutterBottom
                          component="div"
                          sx={{
                            fontSize: '18px',
                            fontWeight: 700,
                            textAlign: 'center',
                            color: '#1976d2',
                            wordBreak: 'break-word',
                          }}
                        >
                          {item?.Link_Value?.ApplicationName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            sx={{
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Grid item>
                              <Typography
                                gutterBottom
                                component="div"
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  textAlign: 'center',
                                  color: '#1976d2',
                                  paddingTop: '8px',
                                }}
                              >
                                Invited By:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              sx={{ paddingTop: '0px', fontSize: '12px' }}
                            >
                              {item?.InvitedByUser?.firstname}
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {item?.InviteStatus === 'Accepted' ? (
                            <Grid
                              container
                              rowSpacing={1}
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                              sx={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingTop: '0px',
                              }}
                            >
                              <Grid item>
                                <Typography
                                  gutterBottom
                                  component="div"
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    textAlign: 'center',
                                    color: '#1976d2',
                                    paddingTop: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  Accepted On:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sx={{ paddingTop: '0px', fontSize: '12px' }}
                              >
                                {moment(item?.InviteAccepted).format(
                                  'Do MMM  YYYY',
                                )}
                              </Grid>
                              <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                sx={{ mt: 3 }}
                              ></Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {item?.InviteStatus === 'Invited' ? (
                            <Grid
                              container
                              rowSpacing={1}
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                              sx={{ mt: 3 }}
                            >
                              <Grid item xs={6} sm={6} md={6}>
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  sx={{ mb: 1, fontWeight: '700' }}
                                  color="success"
                                  onClick={() => handleAcceptInvite(item?.ID)}
                                >
                                  Accept
                                </Button>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="outlined"
                                  color="error"
                                  sx={{ mb: 1, fontWeight: '700' }}
                                  onClick={() =>
                                    handleDeclineApplication(item?.ID)
                                  }
                                >
                                  Decline
                                </Button>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        {/* End Applications_own_Section Section */}
      </div>
    </>
  );
}
export default WithAuth(Dashboard);
